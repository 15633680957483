import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDocumentTypes(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/document_type/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchDocumentType(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/document_type/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addDocumentType(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/document_type', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteDocumentType(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns.delete('/document_type', {params: queryParams} )
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }
  },
}
